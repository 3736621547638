import FileSaver from "file-saver";
import parameterize from "parameterize";
import React, { Component } from "react";
import { Layer, Rect, Stage, Text } from "react-konva";
import { Persist } from "react-persist";
import WebFont from "webfontloader";

import AsyncImage from "./AsyncImage";

import "./Editor.css";
import kiskoHorizontal from "./kisko-horizontal.svg";

const SIZES = {
  instagram: {
    name: "Instagram",
    width: 2048,
    height: 2048,
  },
  twitter: {
    name: "Twitter",
    width: 2048,
    height: 1024,
  },
  facebook: {
    name: "Facebook",
    width: 2400,
    height: 1260,
  },
};

const DEFAULT_STATE = {
  currentSize: "twitter",
  currentTheme: "default",
  currentOverlay: 0,
  currentTextColor: 0,
  headline: "",
  source: "Source: ",
  backgroundImage: "",
  backgroundPosition: {
    x: 0,
    y: 0,
  },
  backgroundScale: 1,
  dataUrl: "",
  fontsLoaded: false,
};

const THEMES = {
  default: {
    name: "Default",
    headlineSize: 84,
    headlineFontFamily: "Work Sans",
    sourceSize: 48,
    sourceFontFamily: "Work Sans",
    padding: 80,
    logo: {
      image: kiskoHorizontal,
      width: 537,
      height: 130,
    },
    fontLoaderConfig: {
      google: {
        families: ["Work Sans"],
      },
    },
    textColors: [
      {
        name: "White",
        color: "#fff",
        text: "black",
      },
      {
        name: "Black",
        color: "#000",
        text: "white",
      },
      {
        name: "Orange",
        color: "#F95346",
        text: "white",
      },
    ],
    overlays: [
      {
        name: "None",
        color: "rgba(255,255,255,0)",
        text: "black",
      },
      {
        name: "Black",
        color: "rgba(17,3,35,0.5)",
        text: "white",
      },
      {
        name: "Blue",
        color: "rgba(43,8,74,0.5)",
        text: "white",
      },
    ],
  },
  alternative: {
    name: "Alternative",
    headlineSize: 84,
    headlineFontFamily: "freight-macro-pro",
    sourceSize: 48,
    sourceFontFamily: "Work Sans",
    padding: 80,
    logo: {
      image: kiskoHorizontal,
      width: 537,
      height: 130,
    },
    fontLoaderConfig: {
      google: {
        families: ["Work Sans"],
      },
      typekit: {
        id: "fmr3oky",
      },
    },
    textColors: [
      {
        name: "White",
        color: "#fff",
        text: "black",
      },
      {
        name: "Black",
        color: "#000",
        text: "white",
      },
    ],
    overlays: [
      {
        name: "80% tint",
        color: "rgba(65,53,79,0.9)",
        text: "white",
      },
      {
        name: "60% tint",
        color: "rgba(112,104,123,0.8)",
        text: "white",
      },
      {
        name: "40% tint",
        color: "rgba(160,154,167,0.8)",
        text: "white",
      },
      {
        name: "20% tint",
        color: "rgba(207,205,211,0.8)",
        text: "white",
      },
    ],
  },
};

class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  fitStageIntoParentContainer() {
    const containerWidth = this.wrapper.offsetWidth;
    const size = SIZES[this.state.currentSize];
    const scale = containerWidth / size.width;

    const translateX = -100 + (scale / 2) * 100;
    // const translateY = (1 - scale) / 2 * -100;
    // console.log(translateY);

    this.setState({
      scalingStyles: {
        transform: `translate(${translateX}%, -50%) scale(${scale})`,
      },
      wrapperStyles: {
        // width: `${scale * size.width}px`,
        height: `${scale * size.height}px`,
      },
    });
  }

  componentDidMount() {
    this.fitStageIntoParentContainer();
    this.handleThemeChange(this.state.currentTheme);
    window.addEventListener("resize", this.fitStageIntoParentContainer.bind(this));
    window.addEventListener("dragover", this.handleDragOver.bind(this));
    window.addEventListener("drop", this.handleFileDrop.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.fitStageIntoParentContainer.bind(this));
    window.removeEventListener("dragover", this.handleDragOver.bind(this));
    window.removeEventListener("drop", this.handleFileDrop.bind(this));
  }

  updateImage(file) {
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function() {
        // For some reason we need to clear out the background image before we
        // update it to the new one.
        this.setState({ backgroundImage: "" }, () => {
          this.setState({
            backgroundImage: reader.result,
          });
        });
      }.bind(this),
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    } else {
      this.setState({
        backgroundImage: "",
      });
    }
  }

  dataURL() {
    const konvaStage = this.stage.getStage();
    return konvaStage.toDataURL();
  }

  downloadName() {
    const { headline } = this.state;

    let downloadName = headline.length ? parameterize(headline) : "image";
    if (downloadName.length > 32) {
      downloadName = downloadName.substring(0, 24);
    }

    return downloadName;
  }

  downloadImage() {
    const konvaStage = this.stage.getStage();
    const { headline } = this.state;

    let downloadName = headline.length ? parameterize(headline) : "image";
    if (downloadName.length > 32) {
      downloadName = downloadName.substring(0, 24);
    }

    const url = konvaStage.toDataURL({ pixelRatio: 1 });
    fetch(url)
      .then(res => res.blob())
      .then(blob => FileSaver.saveAs(blob, `${downloadName}.png`));
  }

  handleReset() {
    this.setState(DEFAULT_STATE);
  }

  handleSizeChange(newSize) {
    this.setState(
      {
        currentSize: newSize,
      },
      () => this.fitStageIntoParentContainer()
    );
  }

  handleThemeChange(newTheme) {
    this.setState({ currentTheme: newTheme, fontsLoaded: false }, () => {
      const theme = THEMES[this.state.currentTheme];
      if (theme.fontLoaderConfig) {
        const config = Object.assign(
          {
            loading: () => console.log("Font loader loading"),
            active: () => {
              console.log("Font loader active");
              const konvaStage = this.stage.getStage();
              konvaStage.batchDraw();
            },
            inactive: () => console.log("Font loader inactive"),
            fontloading: (familyName, fvd) =>
              console.log(`${familyName} (variation ${fvd}) loading`),
            fontactive: (familyName, fvd) => console.log(`${familyName} (variation ${fvd}) active`),
            fontinactive: (familyName, fvd) =>
              console.log(`${familyName} (variation ${fvd}) inactive`),
          },
          theme.fontLoaderConfig
        );
        WebFont.load(config);
      }
    });
  }

  handleBackgroundDrag(event) {
    const {
      target: {
        attrs: { x, y },
      },
    } = event;
    this.setState({
      backgroundPosition: { x, y },
    });
  }

  handleDragOver(event) {
    event.preventDefault();
  }

  handleFileDrop(event) {
    event.preventDefault();
    console.info("File dropped", event);

    let files = [];

    if (event.dataTransfer.items) {
      console.info("Use DataTransferItemList interface to access the file(s)");

      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (event.dataTransfer.items[i].kind === "file") {
          files.push(event.dataTransfer.items[i].getAsFile());
        }
      }
    } else {
      console.info("Use DataTransfer interface to access the file(s)");

      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        files.push(event.dataTransfer.files[i]);
      }
    }

    const images = files.filter(file => file.type.indexOf("image") === 0);
    if (images.length > 0) {
      this.updateImage(images[0]);
    }

    this.removeDragData(event);
  }

  removeDragData(event) {
    console.log("Removing drag data");

    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to remove the drag data
      event.dataTransfer.items.clear();
    } else {
      // Use DataTransfer interface to remove the drag data
      event.dataTransfer.clearData();
    }
  }

  render() {
    const {
      backgroundImage,
      backgroundScale,
      backgroundPosition,
      currentOverlay,
      currentSize,
      currentTextColor,
      currentTheme,
      headline,
      scalingStyles,
      source,
      wrapperStyles,
    } = this.state;

    const size = SIZES[currentSize];
    const theme = THEMES[currentTheme];

    const {
      headlineSize,
      sourceSize,
      padding,
      overlays,
      textColors,
      headlineFontFamily,
      sourceFontFamily,
      logo,
    } = theme;
    const overlay = overlays[currentOverlay] || overlays[0];
    const textColor = textColors[currentTextColor] || textColors[0];

    return (
      <div className="Editor-flexcontainer">
        <div className="Editor-controls">
          <p>
            <label>Headline</label>
            <input
              type="text"
              value={headline}
              onChange={({ target: { value } }) => this.setState({ headline: value })}
            />
          </p>

          <p>
            <label>Source</label>
            <input
              type="text"
              value={source}
              onChange={({ target: { value } }) => this.setState({ source: value })}
            />
          </p>

          <div className="Editor-image">
            <p>
              <label>Photo</label>
              <label htmlFor="upload" className="Editor-upload">
                Choose a file
              </label>
              <input
                id="upload"
                type="file"
                onChange={() => {
                  this.updateImage(this.fileInput.files[0]);
                }}
                ref={input => (this.fileInput = input)}
              />
            </p>
            {backgroundImage && <img src={backgroundImage} alt="" className="Editor-preview" />}
          </div>

          <div className="Editor-size">
            <p>
              <label>Image size</label>
              <input
                type="range"
                min={0}
                max={10}
                step={0.01}
                value={backgroundScale}
                onChange={({ target: { value } }) => this.setState({ backgroundScale: value })}
                disabled={!backgroundImage.length}
              />
            </p>
          </div>

          <p>
            <label>Theme</label>
            <label className="Editor-select">
              <select
                value={currentTheme}
                onChange={({ target: { value } }) => this.handleThemeChange(value)}
              >
                {Object.keys(THEMES).map(key => {
                  const theme = THEMES[key];
                  return (
                    <option key={key} value={key}>
                      {theme.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </p>

          <div className="Editor-swatches-container">
            <span>Text color</span>
            <div className="Editor-swatches">
              {textColors.map((item, index) => {
                return (
                  <label key={index}>
                    <div
                      className="Editor-swatch"
                      style={
                        index === currentTextColor
                          ? {
                              borderColor: "#00FF6C",
                              marginTop: "-0.25rem",
                              boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.15)",
                            }
                          : null
                      }
                    >
                      <div
                        className="Editor-swatch-hover"
                        style={{
                          color: item.text,
                        }}
                      >
                        {item.name}
                      </div>
                      <div
                        className="Editor-swatch-inside"
                        style={{
                          backgroundColor: item.color,
                        }}
                      />
                    </div>
                    <input
                      name="textColor"
                      type="radio"
                      value={index}
                      checked={index === currentTextColor}
                      onChange={({ target: { value } }) => {
                        console.log(currentTextColor);
                        this.setState({
                          currentTextColor: parseInt(value, 10),
                        });
                      }}
                    />
                  </label>
                );
              })}
            </div>
          </div>

          <div className="Editor-swatches-container">
            <span>Overlay</span>

            <div className="Editor-swatches">
              {overlays.map((item, index) => {
                return (
                  <label key={index}>
                    <div
                      className="Editor-swatch"
                      style={
                        index === currentOverlay
                          ? {
                              borderColor: "#00FF6C",
                              marginTop: "-0.25rem",
                              boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.15)",
                            }
                          : null
                      }
                    >
                      <div
                        className="Editor-swatch-hover"
                        style={{
                          color: item.text,
                        }}
                      >
                        {item.name}
                      </div>
                      <div
                        className="Editor-swatch-inside"
                        style={{ backgroundColor: item.color }}
                      />
                    </div>
                    <input
                      name="overlay"
                      type="radio"
                      value={index}
                      checked={index === currentOverlay}
                      onChange={({ target: { value } }) => {
                        this.setState({ currentOverlay: parseInt(value, 10) });
                      }}
                    />
                  </label>
                );
              })}
            </div>
          </div>

          <p>
            <label>Size</label>
            <label className="Editor-select">
              <select
                value={currentSize}
                onChange={({ target: { value } }) => this.handleSizeChange(value)}
              >
                {Object.keys(SIZES).map(key => {
                  const size = SIZES[key];
                  return (
                    <option key={key} value={key}>
                      {size.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </p>

          <div className="Editor-buttons">
            <button className="Editor-reset" onClick={this.handleReset.bind(this)}>
              Reset
            </button>
            <button className="Editor-download" onClick={this.downloadImage.bind(this)}>
              Download image
            </button>
          </div>
        </div>
        <div className="Editor-outside-wrapper" ref={wrapper => (this.wrapper = wrapper)}>
          <div className="Editor-scalable-wrapper" style={wrapperStyles}>
            <div className="Editor-interior" style={scalingStyles}>
              <Stage
                ref={stage => (this.stage = stage)}
                width={size.width}
                height={size.height}
                pixelRatio={2}
                className="Editor-stage"
              >
                <Layer>
                  <Rect fill="#fff" width={size.width} height={size.height} />
                </Layer>
                {backgroundImage ? (
                  <Layer
                    draggable={true}
                    offsetX={-size.width / 2}
                    offsetY={-size.height / 2}
                    x={backgroundPosition.x}
                    y={backgroundPosition.y}
                    onDragEnd={this.handleBackgroundDrag.bind(this)}
                  >
                    <AsyncImage
                      imageURL={backgroundImage}
                      scaleX={backgroundScale}
                      scaleY={backgroundScale}
                      name="backgroundImage"
                    />
                  </Layer>
                ) : null}
                <Layer>
                  <Rect
                    fill={overlay.color}
                    width={size.width}
                    height={size.height}
                    listening={false}
                  />
                </Layer>
                <Layer
                  offsetX={-size.width + logo.width / 2 + padding}
                  offsetY={-size.height + logo.height / 2 + padding}
                >
                  <AsyncImage
                    imageURL={logo.image}
                    width={logo.width}
                    height={logo.height}
                    name="logoImage"
                  />
                </Layer>
                <Layer>
                  <Text
                    text={headline}
                    fontFamily={headlineFontFamily}
                    fontSize={headlineSize}
                    fill={textColor.color}
                    x={padding}
                    y={padding}
                  />
                  <Text
                    text={source}
                    fontFamily={sourceFontFamily}
                    fontSize={sourceSize}
                    fill={textColor.color}
                    x={padding}
                    y={size.height - sourceSize - padding}
                  />
                </Layer>
              </Stage>
            </div>
          </div>
        </div>
        <Persist
          name="editor"
          data={this.state}
          debounce={500}
          onMount={data => this.setState(data)}
        />
      </div>
    );
  }
}

export default Editor;
