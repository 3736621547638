import React, { Component } from "react";
import { Image } from "react-konva";

class AsyncImage extends Component {
  state = {
    image: null
  };
  componentDidMount() {
    const image = new window.Image();
    image.src = this.props.imageURL;
    image.onload = () => {
      // setState will redraw layer
      // because "image" property is changed
      this.setState({
        image: image
      });
    };
  }

  render() {
    const { image } = this.state;
    const { width, height } = this.props;
    let offsetX = 0;
    let offsetY = 0;

    if (width) {
      offsetX = width / 2;
    } else if (image) {
      offsetX = image.width / 2;
    }

    if (height) {
      offsetY = height / 2;
    } else if (image) {
      offsetY = image.height / 2;
    }

    return (
      <Image
        image={image}
        offsetX={offsetX}
        offsetY={offsetY}
        {...this.props}
      />
    );
  }
}

export default AsyncImage;
